import { useEffect, useState, Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { useLocation } from "react-router-dom";
import RootProviders from "./Components/Context/RootProviders";
import Loader from "./Components/CustomComponents/Loader";
import "./App.css";
import "../src/YouePhase2/assets/CSS/loader.css";
import mixpanel from "mixpanel-browser";
import ProfileCompleteSpinner from "./YouePhase2/Onboarding/Module_Two/ProfileCompleteSpinner";
import PaymentLoader from "./YouePhase2/Onboarding/Module_Two/PaymentLoader/PaymentLoader";
import ResponsiveAppBar from "./YouePhase2/components/AppBar";

const UploadViaEmail = lazy(() => import("./YouePhase2/Pages/UploadViaEmail"));

const ProfileSetupExit = lazy(() =>
  import("./YouePhase2/components/ProfileSetupExit")
);
const NextRoleConfrimEditForm = lazy(() =>
  import("./YouePhase2/Onboarding/Module_Two/NextRole/NextRoleConfrimEditForm")
);
const LinkedinSuccessPagePhase2 = lazy(() =>
  import("./YouePhase2/components/LinkedIn/LinkedinSuccessPagePhase2")
);
const OnboardingDashboard = lazy(() =>
  import("./YouePhase2/Onboarding/Module_Two/Dashboard/OnboardingDashboard")
);
const NextRoleLoader = lazy(() =>
  import(
    "./YouePhase2/Onboarding/Module_Two/NextRole/NextRoleLoader/NextRoleLoader"
  )
);
const OnboardPriceInformation = lazy(() =>
  import(
    "./YouePhase2/Onboarding/Module_Two/OnboardPriceModal/OnboardPriceInformation"
  )
);
const PathwayLoader = lazy(() =>
  import("./YouePhase2/Pages/Pathway/PathwayLoader")
);
const ForgotPassword = lazy(() =>
  import("./YouePhase2/components/LoginPages/ResetPassword/ForgotPassword")
);
const CheckYourEmail = lazy(() =>
  import("./YouePhase2/components/LoginPages/ResetPassword/CheckYourEmail")
);
const YouePhase2 = lazy(() => import("./YouePhase2/components/YouePhase2"));
const CreatePassword = lazy(() =>
  import("./YouePhase2/components/LoginPages/ResetPassword/CreatePassword")
);
const ProfileBeingSetUp = lazy(() =>
  import("./YouePhase2/components/ProfileBeingSetUp/ProfileBeingSetUp")
);

const ProfilePage = lazy(() => import("./YouePhase2/Pages/ProfilePage"));
const Onboarding = lazy(() => import("./YouePhase2/Onboarding/Onoarding"));
const NextRole = lazy(() =>
  import("./YouePhase2/Onboarding/Module_Two/NextRole/NextRole")
);
const AccountSeting = lazy(() =>
  import("./YouePhase2/Onboarding/Module_Two/AcccountSettings/AccountSeting")
);
const Roadmap = lazy(() => import("./YouePhase2/Pages/Roadmap/Roadmap"));
const Phase2Pathway = lazy(() =>
  import("./YouePhase2/Pages/Pathway/Phase2Pathway")
);
const ProfilePagePhase2 = lazy(() => import("./YouePhase2/Pages/ProfilePage"));
const PhaseTwoDashBoard = lazy(() => import("./YouePhase2/Pages/Dashboard"));
const YoueAiPage = lazy(() => import("./YouePhase2/Pages/YoueAIPage"));
const HomeLandingPage = lazy(() =>
  import("./YouePhase2/NewLandingPage/HomeLandingPage")
);
const SignInPages = lazy(() =>
  import("./YouePhase2/components/LoginPages/SignInPages")
);
const TermsofService = lazy(() =>
  import("./YouePhase2/NewLandingPage/TermsOfService/TermsofService")
);
const TermsAndConditions = lazy(() =>
  import("./YouePhase2/NewLandingPage/TermsAndConditions/TermsAndConditions")
);

mixpanel.init(`${process.env.REACT_APP_MIXPANEL_TOKEN}`, {
  debug: true,
  ignore_dnt: true,
});

function App() {
  const { pathname } = useLocation();
  const [showHeader, setShowHeader] = useState(false);
  const [showAppBar, setShowAppbar] = useState(false);

  useEffect(() => {
    const bodyTag = document.getElementsByTagName("body")[0];
    if (pathname === "/email-signup") {
      bodyTag.classList.add("signup-flow");
    } else {
      bodyTag.classList.remove("signup-flow");
    }
    setShowHeader(
      pathname === "/dashboardPhase2" ||
        pathname === "/youe_ai_page" ||
        pathname === "/profile" ||
        pathname === "/learn" ||
        pathname === "/road-map" ||
        pathname === "/account_setting_phase2" ||
        pathname === "/phase2-pathway" ||
        pathname === "/next-role" ||
        pathname === "/payment/success"
    );
  }, [pathname]);

  return (
    <div className="App" style={{ backgroundColor: "#f8f8f8" }}>
      <RootProviders>
        {showHeader && <ResponsiveAppBar />}
        <Loader />
        <Suspense fallback={<div></div>}>
          <Routes>
            <Route path="/sign-up" element={<Onboarding />} />

            <Route path="/profile_setup" element={<ProfileBeingSetUp />} />

            <Route path="/sign-up" element={<YouePhase2 />} />
            <Route path="/sign-in" element={<SignInPages />} />
            <Route
              path="/linkedin-signin/success"
              element={<LinkedinSuccessPagePhase2 />}
            />
            <Route
              path="/linkedin-signup/success"
              element={<LinkedinSuccessPagePhase2 />}
            />
            <Route path="/exit" element={<ProfileSetupExit />} />
            <Route path="/next_role_loader" element={<NextRoleLoader />} />
            <Route path="/youe_ai_page" element={<YoueAiPage />} />
            <Route path="/account_setting_phase2" element={<AccountSeting />} />

            <Route path="/profile" element={<ProfilePage />} />

            <Route path="/payment/success" element={<PaymentLoader />} />

            <Route
              path="/payment/failure"
              element={<OnboardPriceInformation />}
            />
            <Route
              path="/profile-complete"
              element={<ProfileCompleteSpinner />}
            />

            <Route
              path="/dashboard-onboarding"
              element={<OnboardingDashboard />}
            />

            <Route path="/dashboardPhase2" element={<PhaseTwoDashBoard />} />
            <Route path="/profilePhase2" element={<ProfilePage />} />
            <Route path="/next-role" element={<NextRole />} />
            <Route
              path="/next_role_edit"
              element={<NextRoleConfrimEditForm />}
            />
            <Route path="/profilePagePhase2" element={<ProfilePagePhase2 />} />
            <Route path="/road-map" element={<Roadmap />} />
            <Route path="/phase2-pathway" element={<Phase2Pathway />} />
            <Route path="/pathway_loader" element={<PathwayLoader />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/check-your-email" element={<CheckYourEmail />} />
            <Route
              path="/reset-password/:id/:name"
              element={<CreatePassword />}
            />
            <Route path="/" element={<HomeLandingPage />} />
            <Route path="/privacy-policy" element={<TermsAndConditions />} />
            <Route path="/terms-service" element={<TermsofService />} />
            <Route path="/resume-upload/:userId/:token" element={<UploadViaEmail />} />
          </Routes>
        </Suspense>
      </RootProviders>
    </div>
  );
}

export default App;
